
.HomePage{
    padding-top: 20rem;
    position: absolute;
    background: url('https://wallpapercave.com/wp/wp3531097.jpg');
    top: 35%;
    
    transform: translate(-50%, -50%);
    width: 155%;
    height: 65rem;
    margin-left: 0;
}
.para2{
    margin-top: 15rem;
    margin-left: 55rem;
    margin-right: 5rem;
}
.NavBar1 {
    margin-top: 10rem;
    height: 70vh;
    .nav1 {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  
      .profile1 {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
        
        img {
          margin-top: 10rem;
          width: 30%;
          
          border: 1px solid rgb(34, 25, 82);
          margin-bottom: 0.5rem;
        }
      }
    }
}

.hero-text{
    margin-top: 3rem;
    color: white;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 5px;
    span{
        color: #007bff;
        
    }
}

.h-sub-text{
    margin: 2rem;
    font-size: 1.5rem;
    color: white;
    text-align: center;
}
.h-sub-text1{
    font-size: 1.5rem;
    margin-top: -2rem;
    color: #007bff;
    text-align: center;
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    .icon-holder{
        margin-right: 1rem;
        .icon{
           font-size: 2.8rem;
           color: #a4acc4;
           transition: all .4s ease-in-out;
        }
        .fb:hover{
            color: blue;
        }
        .gh:hover{
            color: rgba(212, 45, 212, 0.788);
        }
        .yt:hover{
            color: rgb(0, 0, 0);
        }
    }
    .icon-holder1{
        margin-right: 1rem;
        .icon{
           font-size: 2rem;
           color: #a4acc4;
           transition: all .4s ease-in-out;
        }
        .fb:hover{
            color: blue;
        }
        .gh:hover{
            color: rgba(212, 45, 212, 0.788);
        }
        .yt:hover{
            color: rgb(0, 0, 0);
        }
    }
}