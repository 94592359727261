.PortfolioPage{
    position: absolute;
    background: url('https://wallpapercave.com/wp/wp3616975.jpg');
    color: white;
    
    transform: translate(-50%, -50%);
    width: 155%;
    height: 165rem;
    
    margin-left: 0;
}
.para3{
    
        margin-top: 75rem;
        margin-left: 55rem;
        margin-right: 5rem;
    }