@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
@import "./styles/layout";
@import "./styles/Navbar";
@import "./styles/Typo";
@import "./styles/Title";
@import "./styles/Typo";
@import "./styles/about";
@import "./styles/contacts";
@import "./styles/services";
@import "./styles/skill";
@import "./styles/timeline";
@import "./styles/Portfolio";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poiret One' , cursive;
}
body {
  color: #9399ad;
  font-family: cursive;
  font-size: 1.2rem;
}
.Home{
  margin-bottom: 2rem;
}
