.NavBar {
  height: 100vh;
  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .profile {
      width: 100%;
      text-align: center;
      color: white;
      margin-top: 1rem;
      border-bottom: 1px solid rgb(38, 38, 71);
      img {
        width: 60%;
        border-radius: 100%;
        border: 1px solid rgb(34, 25, 82);
        margin-bottom: 0.5rem;
      }
    }

    .nav-items {
      width: 100%;
      text-align: center;
      .nav-item {
        list-style: none;
        text-align: center;
        a {
          text-decoration: none;
          font-size: inherit;
          color: white;
          display: block;
          padding: 0.5rem 0;
          z-index: 1;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.4rem;
            height: 100%;
            background-color: #007bff;
            transform-origin: bottom;
            transform: scale(0);
            overflow-x: hidden;
            z-index: -1;
            transition: transform 0.4s,
              0.2s width 0.5s cubic-bezier(.79,.12,.52,.76);
          }

          &:hover::before {
            width: 100%;
            transform: scale(1);
          }
        }
        img {
          width: 5%;
        }
      }
      
    }
    
    .footer {
      width: 100%;
      border-top: 1px solid black;
      p {
        text-align: center;
        padding: 1rem 0;
      }
    }
  }
}

.active {
  background-color: #007bff;
}
