.SkillsSection{
   
}

.skills-container{
    .skill-title{
        font-size: 1.4rem;
        font-weight: 400;
        color: white;
    }

    .skill-bar{
        display: flex;
        align-items: center;
        
        .skill-text{
            color: white;
        }
        .skill-progress{
            width: 100%;
           .progress{
               width: 100%;
               height: .5rem;
               background-color: red;
               margin-left: 1rem;
               background-color: #ffffff3f;
               position: relative;
               .inner-pregress{
                    position: absolute;
                    background-color: #037FFF;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    &::after{
                    transition: all 0.4s ease-in-out;
                    width: 100%;
                    }
                    &:hover::after{
                        width: 100%;
                    }
               }
           }
        }
    }
}