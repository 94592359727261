.back{
    background: url('https://images.unsplash.com/photo-1533035353720-f1c6a75cd8ab?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80');
    margin-left: -40rem;
    margin-right: -20rem;
    margin-top: -25rem;
    margin-bottom: -20rem;
    height: 120%;
}
.title1{
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top:20rem;
    margin-left:40rem;
    
    margin-right: 20rem;
}
.ContactPage{
    margin-left:40rem;
    margin-bottom: 20rem;
    margin-right: 20rem;
    .map-sect{
        
        margin-bottom: 20rem;
    }
}
.ContactItem{
    width: 100%;
    margin-bottom: 20rem;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
    .contact{
        display: flex;
        align-items: center;
        background-color:#d0d5e6 ;
        padding: 3rem 0;
        
        .right-items{
            margin-left: 2rem;
        }
        img{
            padding: 1rem;
            border: 1px solid #007bff;
            margin-left: 2rem;
            width: 16%;
        }
        .right-items{
            h6{
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
    &:hover{
        border-top: 8px solid #007bff;
        background-color: #e6e9f3;
        transition: all .4s ease-in-out;
        img{
            background-color: #007bff;
            color: antiquewhite;
        }
    }
}

.ContactPage{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 6rem;
    @media screen and (max-width: 1270px){
        grid-template-columns: repeat(1, 1fr);
        .map-sect{
            width: 100%;
            height: 50vh;
            margin-bottom: 1rem;
        }
    }
    .contact-sect{
        width: 100%;
    }
}
.map-sect{
    width: 97%;
    padding: 1rem;
    background-color:#d2d7e6;
    iframe{
        width: 100%;
        height: 100%;
    }
}

.contact-sect{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.title{
    margin-bottom: 5rem;
}